body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 600px is the MUI breakpoint from sm to xs */
@media (max-width: 600px) {
  body {
    font-size: 14px;
  }
}

/* FONT AWESOME PRO */
/* LICENSE INFO IN .npmrc */
@import url(~@fortawesome/fontawesome-pro/css/all.min.css);
